import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { compose } from "redux";

import { useHistory, withRouter } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCity, faUser, faBuilding, faBriefcase } from "@fortawesome/pro-light-svg-icons";
import { Button } from "@ts-digital/vrc";
import { Link } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import { toUpper } from "ramda";
import { faExclamationCircle, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { faTimes, faUserFriends } from "@fortawesome/pro-regular-svg-icons";

import { ItemLogo } from "./item-logo/index";
import "./style.css";
import ElevioElements from "../../elevio-elements";
import { certificationStatus as certificationStatusEnum } from "../../../utils/constant";
import { mergeStatus } from "../../../utils/state-utils/get-merged-status";
import { isItemAdmin, canModifyData, hasAdminOwnerUser } from "../../../utils/get-roles";
import { UploadButton } from "./upload-button";
import { translateMessage } from "../../../i18n";
import {
    CloseIcon,
    Company,
    Name,
    Info,
    ButtonContainer,
    NotificationWrapper,
    NotificationContainer,
    NotificationIconContainer,
    NotificationDescription,
    InfoContainer,
    Container,
    TsPayInfoContainer
} from "./styled";
import TalksAdvertisingReminder from "../../talks-banner/components/talks-advertising-reminder";

export const CompanyInfo = ({
    company,
    configTsPayStatus,
    hasStudio,
    isTalksBannerClosed,
    isTSPayOrEBSActive,
    match,
    onClickManageButton,
    partecipateTalks,
    provisioningInfo,
    rejectionMessage,
    talksBannerEnabled,
    user,
    users,
    logoUrl,
    logoFinishedLoading,
    talksLinkEnabled,
    hasActiveArcService
}) => {
    const [showTsPayConfigNotification, setShowTsPayConfigNotification] = useState(false);
    const history = useHistory();

    useEffect(() => {
        if (isTSPayOrEBSActive && configTsPayStatus === "NOT_LINKED") {
            setShowTsPayConfigNotification(true);
        }
    }, [configTsPayStatus, isTSPayOrEBSActive, match.params.item, setShowTsPayConfigNotification]);

    const isFakeCompany = !company.base.identifier.taxId && !company.base.identifier.vatNumber;
    const certificationStatus = (company, match) => {
        const status = company.base.status.certificationStatus
            ? toUpper(company.base.status.certificationStatus)
            : null;

        switch (status) {
            case certificationStatusEnum.AWAITING_UPLOAD:
                return (
                    <>
                        <span className="certification-status">
                            <FormattedMessage id="c-services-view.ricertificazione.upload" />
                        </span>
                        <ButtonContainer>
                            <UploadButton
                                itemId={match.params.item}
                                messageId="c-services-view.valida-ora"
                                elevioId="221"
                            />
                        </ButtonContainer>
                    </>
                );
            case certificationStatusEnum.AWAITING_APPROVAL:
                return (
                    <>
                        <span className="certification-status">
                            <FormattedMessage id="c-services-view.ricertificazione.inProgress" />
                        </span>
                    </>
                );
            case certificationStatusEnum.CERTIFIED:
                return (
                    <>
                        <span className="certification-status">
                            <FormattedMessage id="c-services-view.certificata" />
                        </span>
                    </>
                );
            default:
                return (
                    <>
                        <span className="certification-status">
                            <FormattedMessage id="c-services-view.validata" />
                        </span>
                    </>
                );
        }
    };

    let uploadDocument = (
        <>
            <span>
                <FormattedMessage id="c-services-view.cosa-aspetti" />
            </span>
            <ButtonContainer>
                <UploadButton itemId={match.params.item} messageId="c-services-view.valida-ora" elevioId="221" />
            </ButtonContainer>
        </>
    );

    let uploadCertification = certificationStatus(company, match);

    let companyStatus = company.base.status.status.includes("PENDING")
        ? {
              type: "warning",
              message: translateMessage("general.attention"),
              description: <FormattedMessage id="c-services-view.stiamo-validando" />
          }
        : company.base.status.status.includes("VALIDATED")
        ? {
              type: "success",
              message: translateMessage("general.congratulations"),
              description: uploadCertification
          }
        : {
              type: "error",
              message: translateMessage("general.attention"),
              description: uploadDocument
          };

    const status = mergeStatus(company.base.status);

    const usersIcon = {
        STUDIO: faBriefcase,
        COMPANY: faBuilding,
        PERSON: faUser,
        BUILDING: faCity,
        ASSOCIATION: faUserFriends
    };

    let editable = false;
    if (canModifyData(user && user.user ? user.user : user, company.base.id)) {
        editable = true;
    } else {
        editable = !hasAdminOwnerUser(company.base.id, users) ? true : false;
    }

    return (
        <Container>
            <ItemLogo
                history={history}
                itemId={match.params.item}
                url={logoUrl}
                isLoading={!logoFinishedLoading}
                defaultLogo={usersIcon[company.base.details.classifier]}
                editable={editable}
                userNcsid={user.user.profile.ncsId}
            />
            <Company id="company-info">
                {company.base.details.classifier === "PERSON" ? (
                    <Name>{`${company.base.details.firstName} ${company.base.details.lastName}`}</Name>
                ) : (
                    <Name>{company.base.details.description}</Name>
                )}
                <Info>
                    <span>
                        <FormattedMessage id="general.stato" />
                        {": "}
                    </span>
                    <span className={`service-status-${status}`}>
                        <FormattedMessage id={`general.status.${status}`} />{" "}
                        {(status === "REJECTED" || status === "AWAITING_UPLOAD") && (
                            <FormattedMessage id={`c-company-info.rejection-message`} values={{ rejectionMessage }} />
                        )}
                        <ElevioElements idArticle="222" />
                    </span>
                    <br />
                    <span>
                        <FormattedMessage id="general.codice-fiscale" /> {": " + company.base.identifier.taxId}
                    </span>
                    <br />
                    {company.base.details.classifier !== "PERSON" && (
                        <span>
                            <FormattedMessage id="general.partita-iva" />{" "}
                            {": " + (company.base.identifier.vatNumber ? company.base.identifier.vatNumber : "-")}
                        </span>
                    )}
                    <div
                        style={{
                            marginTop: "10px"
                        }}
                    >
                        <Link to={"/"}>
                            <Button size="small" onClick={() => onClickManageButton()}>
                                <FormattedMessage id="c-manage-data.user-edit-button.popover.manage" />
                            </Button>
                        </Link>
                    </div>
                </Info>
            </Company>
            <NotificationWrapper>
                {talksBannerEnabled && isTalksBannerClosed && (
                    <TalksAdvertisingReminder
                        hasStudio={hasStudio}
                        partecipateTalks={partecipateTalks}
                        talksLinkEnabled={talksLinkEnabled}
                    />
                )}
                {!talksBannerEnabled &&
                    !company.base.status.externallyValidated &&
                    isItemAdmin(company.base.id, user) &&
                    (company.base.status.status !== "VALIDATED" ||
                        (company.base.status.certificationStatus !== null &&
                            company.base.status.certificationStatus !== "CERTIFIED")) &&
                    !isFakeCompany && (
                        <NotificationContainer>
                            <NotificationIconContainer>
                                <FontAwesomeIcon icon={faExclamationCircle} />
                            </NotificationIconContainer>
                            <InfoContainer>
                                <NotificationDescription>
                                    <b>{companyStatus.message}: </b>
                                    {companyStatus.description}
                                </NotificationDescription>
                            </InfoContainer>
                        </NotificationContainer>
                    )}
                {!talksBannerEnabled &&
                    (provisioningInfo.status === "initialized" ||
                        provisioningInfo.status === "downloaded" ||
                        provisioningInfo.status === "rejected") &&
                    !hasActiveArcService && (
                        <NotificationContainer>
                            <NotificationIconContainer>
                                <FontAwesomeIcon icon={faExclamationCircle} />
                            </NotificationIconContainer>
                            <InfoContainer>
                                <NotificationDescription>
                                    <b>
                                        <FormattedMessage id="general.attention" />
                                        {": "}
                                    </b>
                                    <FormattedMessage id="c-cct-provisioning-complete-information" />
                                </NotificationDescription>
                                <ButtonContainer onClick={() => history.push(`/${match.params.item}/services/storage`)}>
                                    <Button>
                                        <FormattedMessage id="c-cct-provisioning-complete-information-button" />
                                    </Button>
                                </ButtonContainer>
                            </InfoContainer>
                        </NotificationContainer>
                    )}
                {!talksBannerEnabled &&
                    provisioningInfo.status === "validated" &&
                    provisioningInfo.rdcToBeFixed === true && (
                        <NotificationContainer>
                            <NotificationIconContainer>
                                <FontAwesomeIcon icon={faExclamationCircle} />
                            </NotificationIconContainer>
                            <InfoContainer>
                                <NotificationDescription>
                                    <b>
                                        <FormattedMessage id="general.attention" />
                                        {": "}
                                    </b>
                                    <FormattedMessage id="c-cct-provisioning-rdc-to-be-fixed-warning" />
                                </NotificationDescription>
                                <ButtonContainer>
                                    <Button
                                        onClick={() =>
                                            history.push(
                                                `/${match.params.item}/services/storage/edit-cct/generate-document/${match.params.item}`
                                            )
                                        }
                                    >
                                        <FormattedMessage id="general.change-data" />
                                    </Button>
                                </ButtonContainer>
                            </InfoContainer>
                        </NotificationContainer>
                    )}
                {!talksBannerEnabled && showTsPayConfigNotification && provisioningInfo.rdcToBeFixed !== true && (
                    <NotificationContainer tsPay={true}>
                        <NotificationIconContainer tsPay={true}>
                            <FontAwesomeIcon icon={faInfoCircle} />
                        </NotificationIconContainer>
                        <InfoContainer>
                            <NotificationDescription>
                                <TsPayInfoContainer>
                                    <div>
                                        <b>
                                            <FormattedMessage id="apps.TSPAY.title" />
                                            {": "}
                                        </b>
                                        <FormattedMessage id="tspay-configuration-warning-message" />
                                    </div>
                                    <CloseIcon
                                        icon={faTimes}
                                        onClick={() => {
                                            setShowTsPayConfigNotification(false);
                                        }}
                                    />
                                </TsPayInfoContainer>
                            </NotificationDescription>
                            <ButtonContainer>
                                <Button onClick={() => history.push(`/${match.params.item}/services/tspay`)}>
                                    <FormattedMessage id="tspay-configure-button" />
                                </Button>
                            </ButtonContainer>
                        </InfoContainer>
                    </NotificationContainer>
                )}
            </NotificationWrapper>
        </Container>
    );
};

CompanyInfo.propTypes = {
    company: PropTypes.object.isRequired,
    configTsPayStatus: PropTypes.string,
    hasStudio: PropTypes.bool,
    isTalksBannerClosed: PropTypes.bool,
    isTSPayOrEBSActive: PropTypes.bool,
    match: PropTypes.object.isRequired,
    partecipateTalks: PropTypes.func,
    provisioningInfo: PropTypes.object,
    rejectionMessage: PropTypes.string,
    user: PropTypes.object,
    onClickManageButton: PropTypes.func.isRequired,
    talksBannerEnabled: PropTypes.bool,
    tsPayInfo: PropTypes.func,
    hasActiveArcService: PropTypes.bool
};

const composedHOC = compose(injectIntl, withRouter);

export default composedHOC(CompanyInfo);
