export const DOMAIN = process.env.REACT_APP_DOMAIN || "-dev.agyo.io";

const envValues = {
    dev: "dev",
    test: "test",
    master: "prod"
};
export const ENV = envValues[process.env.REACT_APP_ENV] || "dev";

//REGISTRY
export const USER_WRITE = process.env.REACT_APP_USER_WRITE || "http://localhost";
export const USER_WRITE_REST = process.env.REACT_APP_USER_WRITE_REST || "http://localhost";
export const REGISTRY_WRITE_REST = process.env.REACT_APP_REGISTRY_WRITE_REST || "http://localhost";
export const REGISTRY_READ = process.env.REACT_APP_REGISTRY_READ || "http://localhost";
export const REGISTRY_READ_REST = process.env.REACT_APP_REGISTRY_READ_REST || "http://localhost";
export const ATOKA_URL = process.env.REACT_APP_ATOKA_URL || "http://localhost";
export const AGYO_CONTRACT_API_URL = process.env.REACT_APP_AGYO_CONTRACT_API_URL || "http://localhost";
export const CERTIFICATION_API_URL = process.env.REACT_APP_CERTIFICATION_API_URL || "http://localhost";

//AUTH
export const COMMON_LOGIN_URL = process.env.REACT_APP_COMMON_LOGIN_URL || "http://localhost:3001";
export const COMMON_LOGIN_API_URL = process.env.REACT_APP_COMMON_LOGIN_API_URL || "http://localhost";

//APPS
export const FIRMA_CONSOLE = process.env.REACT_APP_FIRMA_URL || "http://localhost";
export const OLD_PORTAL_URL = process.env.REACT_APP_OLD_PORTAL_URL || "http://localhost";
export const PRIVACY_URL = process.env.REACT_APP_PRIVACY_URL || "http://localhost";
export const TELEMATICI_CONSOLE = process.env.REACT_APP_TELEMATICI_CONSOLE || "http://localhost";
export const CONSOLE_URL = process.env.REACT_APP_CONSOLE_URL || "http://localhost";
export const CCT_CONSOLE = process.env.REACT_APP_CCT_CONSOLE || "http://localhost";
export const CDC_CONSOLE = process.env.REACT_APP_CDC_CONSOLE || "http://localhost";
export const CONSOLE_AGYOLOG = process.env.REACT_APP_CONSOLE_AGYOLOG || "http://localhost";
export const METERING_API_URL = process.env.REACT_APP_METERING_API_URL;
export const METERING_READ_API_URL = process.env.REACT_APP_METERING_READ_API_URL;
export const METERING_STORE_UPGRADE_ENDPOINT = process.env.REACT_APP_METERING_STORE_UPGRADE_ENDPOINT;
export const METERING_CONSUMPTIONS_DASHBOARD_URL = process.env.REACT_APP_METERING_CONSUMPTIONS_DASHBOARD_URL;
export const CONSOLE_FATTURAZIONE_URL = process.env.REACT_APP_CONSOLE_FATTURAZIONE_URL || "http://localhost:8080";
export const CONSOLE_FATTURAZIONE_20_URL = process.env.REACT_APP_CONSOLE_FATTURAZIONE_20_URL || "http://localhost:8080";
export const CONSOLE_PEOPLEAPP = process.env.REACT_APP_PEOPLEAPP_CONSOLE || "http://localhost:8080";
export const OTC_INVOICE_TRADING_WEBAPP_ENABLED = process.env.REACT_APP_OTC_INVOICE_TRADING_WEBAPP_ENABLED === "true";
export const OTC_INVOICE_TRADING_WEBAPP_URL =
    process.env.REACT_APP_OTC_INVOICE_TRADING_WEBAPP_URL || "http://localhost:8080";
export const CRAM_FRONT_WEBAPP_URL = process.env.REACT_APP_CRAM_FRONT_WEBAPP_URL || "http://localhost:8080";
export const BILANCIO_SAAS = process.env.REACT_APP_BILANCIO_SAAS || "http://localhost";
export const SPEND_MANAGMENT = process.env.REACT_APP_SPEND_MANAGMENT || "http://localhost";
export const CREDIT_RISK_ASSESTMENT_URL = process.env.REACT_APP_CREDIT_RISK_ASSESTMENT_URL || "http://localhost";
export const AGRO_URL = process.env.REACT_APP_AGRO_URL || "http://localhost";
export const NIUMABONUS_URL = process.env.REACT_APP_NIUMABONUS_URL || "http://localhost";
export const TS_PAY_URL = process.env.REACT_APP_TS_PAY_URL || "http://localhost";
export const SPID_WEBAPP_URL = process.env.REACT_APP_SPID_WEBAPP_URL || "http://localhost";
export const ANALYTICS_WEBAPP_URL = process.env.REACT_APP_ANALYTICS_WEBAPP_URL || "http://localhost";
export const BUSINESS_INFORMATION_WEBAPP_URL =
    process.env.REACT_APP_BUSINESS_INFORMATION_WEBAPP_URL || "http://localhost";
export const ARCHIVE_WEBAPP_URL = process.env.REACT_APP_ARCHIVE_WEBAPP_URL || "http://localhost";
export const DRAG_URL = process.env.REACT_APP_DRAG_URL || "http://localhost";
export const PECMANAGER_URL = process.env.REACT_APP_PECMANAGER_URL || "http://localhost";
export const AML_URL = process.env.REACT_APP_AML_URL || "http://localhost";
export const TS_HOSPITALITY_URL = process.env.REACT_APP_TS_HOSPITALITY_URL || "http://localhost";
export const HR_URL = process.env.REACT_APP_HR_URL || "http://localhost";
export const DEEP_TIER_URL = process.env.REACT_APP_DEEP_TIER_URL || "http://localhost";

//SERVICES
export const CCT_PROVISIOING_USER_READ_URL = process.env.REACT_APP_CCT_PROVISIOING_USER_READ_URL || "http://localhost";
export const CCT_PROVISIOING_USER_WRITE_URL =
    process.env.REACT_APP_CCT_PROVISIOING_USER_WRITE_URL || "http://localhost";
export const SERVICES_SUBSCRIPTION_URL = process.env.REACT_APP_SERVICES_SUBSCRIPTION_URL || "http://localhost";
export const SERVICES_SUBSCRIPTION_V2_URL = process.env.REACT_APP_SERVICES_SUBSCRIPTION_V2_URL || "http://localhost";
export const SERVICE_SUBSCRIPTION_V2_URL = process.env.REACT_APP_SERVICE_SUBSCRIPTION_V2_URL || "http://localhost";
export const SDI_API_URL = process.env.REACT_APP_SDI_API_URL || "http://localhost";
export const A_FISCALI_IVA_API_URL = process.env.REACT_APP_A_FISCALI_IVA_API_URL || "http://localhost";
export const B2B_PASSIVE_API_URL = process.env.REACT_APP_B2B_PASSIVE_API_URL || "http://localhost";
export const BILLING_API_URL = process.env.REACT_APP_BILLING_API_URL || "http://localhost";
export const SIGN_API_READ_URL = process.env.REACT_APP_SIGN_API_READ_URL || "http://localhost";
export const SIGN_API_WRITE_URL = process.env.REACT_APP_SIGN_API_WRITE_URL || "http://localhost";
export const API_UPLOAD_URL = process.env.REACT_APP_API_UPLOAD_URL || "http://localhost";
export const CBCR_API_URL = process.env.REACT_APP_CBCR_API_URL || "http://localhost";
export const NOTE_SPESE_API_URL = process.env.REACT_APP_NOTE_SPESE_API_URL || "http://localhost";
export const CONNECTION_READ_API = process.env.REACT_APP_CONNECTION_READ_API || "http://localhost";
export const CONNECTION_WRITE_API = process.env.REACT_APP_CONNECTION_WRITE_API || "http://localhost";
export const GDPR_LOG_CONFIG_URL = process.env.REACT_APP_GDPR_LOG_CONFIG_URL || "http://localhost";
export const NOTIFICATION_WRITE_URL = process.env.REACT_APP_NOTIFICATION_WRITE_URL || "http://localhost";
export const NOTIFICATION_READ_URL = process.env.REACT_APP_NOTIFICATION_READ_URL || "http://localhost";
export const WAITINGLIST_WRITE_URL = process.env.REACT_APP_WAITINGLIST_WRITE_URL || "http://localhost";
export const WAITINGLIST_READ_URL = process.env.REACT_APP_WAITINGLIST_READ_URL || "http://localhost";
export const WORKSPACE_API_READ_URL = process.env.REACT_APP_WORKSPACE_API_READ_URL || "http://localhost";

// feature toogles
export const COMPANIES_ID_ENABLED_FOR_FATTURAZIONE_20 =
    process.env.REACT_APP_COMPANIES_ID_ENABLED_FOR_FATTURAZIONE_20 || "";
export const ENABLE_NOTIFICATION_SETTING = process.env.REACT_APP_ENABLE_NOTIFICATION_SETTING || "enable";
export const ENABLE_METERING = process.env.REACT_APP_ENABLE_METERING || "enable";
export const ENABLE_INTERMEDIARIES_SETTING = process.env.REACT_APP_ENABLE_INTERMEDIARIES_SETTING || "disable";
export const ENABLE_AGYO_STATUS = process.env.REACT_APP_ENABLE_AGYO_STATUS || "disable";
export const ENABLE_GOV_CODE = process.env.REACT_APP_ENABLE_GOV_CODE || "enable";
export const ENABLE_COOKIE = process.env.REACT_APP_ENABLE_COOKIE || "enable";
export const DISABLE_USER_LIST_NOTOWNER = process.env.REACT_APP_DISABLE_USER_LIST_NOTOWNER || "false";
export const DISABLE_ELEVIO_FOR_FATTURAZIONE = process.env.REACT_APP_DISABLE_ELEVIO_FOR_FATTURAZIONE || "false";
export const ENABLE_WEB_NOTIFICATIONS = process.env.REACT_APP_ENABLE_WEB_NOTIFICATIONS === "true";

let enableLogs = true;
if (process.env.REACT_APP_ENABLE_CONSOLE_LOGS === "false") {
    enableLogs = false;
}

export const ENABLE_CONSOLE_LOGS = enableLogs;
export const API_URL = process.env.REACT_APP_API_URL || "http://localhost";
export const CONFIG_URL = process.env.REACT_APP_CONFIG_URL || "http://localhost";

export const PUBLIC_URL = process.env.PUBLIC_URL || "";

export const TS_DIGITAL_IMAGES =
    process.env.REACT_APP_TS_DIGITAL_IMAGES || "https://appdev.teamsystemdigital.com/images/ts-digital/";
export const TS_DIGITAL_SIGN_DRIVER =
    process.env.REACT_APP_TS_DIGITAL_SIGN_DRIVER ||
    "https://b2bstaticprod.blob.core.windows.net/static-apps/driver_sw_tavoletta/Driver_TabletPC.zip";

export const PRIVACY_POLICY_URL =
    process.env.REACT_APP_PRIVACY_POLICY_URL || "https://tc.teamsystem.com/InformativaPrivacyTSDigital.pdf";

export const ARCHIVE_COOKIES_URL =
    process.env.REACT_APP_ARCHIVE_COOKIES_URL || "https://tc.teamsystem.com/schedeservizioTSdigital.pdf";

export const ACT_OF_ENTRUSTMENT_NAME = "TSDigitalActOfEntrustment.pdf";

export const RELEASENOTES_URL = "https://agyo.releasenotes.io";

export const ENABLE_APP_VERSION_CHECK = process.env.REACT_APP_ENABLE_APP_VERSION_CHECK || "disable";
export const CURRENT_VERSION = process.env.REACT_APP_CURRENT_VERSION || "67c9963e";
export const ENABLE_PASSWORD_EXPIRATION_WARN = process.env.REACT_APP_ENABLE_PASSWORD_EXPIRATION_WARN || "enable";

const enableItemCountries = process.env.REACT_APP_ENABLE_ITEM_COUNTRIES
    ? process.env.REACT_APP_ENABLE_ITEM_COUNTRIES.split(",")
    : ["IT", "CH", "SM"];

export const ENABLE_ITEM_COUNTRIES = enableItemCountries;

export const TSDIGITAL_ENDPOINT =
    process.env.REACT_APP_TSDIGITAL_ENDPOINT || "https://app.teamsystemdigital.com/portale/";

export const RDC_VADEMECUM_URL =
    "https://tsdigitalpublic.blob.core.windows.net/documents/CS-VademecumResponsabileConservazione.pdf";

// Gainsight
export const GAINSIGHT_APP_NAME = "ts-portale";

// Notifications Center
export const TIMER_FETCH_WEB_NOTIFICATIONS_STATUS =
    process.env.REACT_APP_TIMER_FETCH_WEB_NOTIFICATIONS_STATUS === "false"
        ? false
        : process.env.REACT_APP_TIMER_FETCH_WEB_NOTIFICATIONS_STATUS;
export const NCS_ARRAY_CHANNELS = [
    {
        type: "EMAIL",
        name: "Email",
        visible: process.env.REACT_APP_NCS_EMAIL_CHANNEL_VISIBLE === "true"
    },
    {
        type: "WEB",
        name: "Web",
        visible: process.env.REACT_APP_NCS_WEB_CHANNEL_VISIBLE === "true"
    },
    {
        type: "PUSH",
        name: "Push",
        visible: process.env.REACT_APP_NCS_PUSH_CHANNEL_VISIBLE === "true"
    }
];

export const ENABLE_ATECO_CODE = process.env.REACT_APP_ENABLE_ATECO_CODE === "true";

export const ENABLE_EDIT_WITHOUT_ADMIN = process.env.REACT_APP_ENABLE_EDIT_WITHOUT_ADMIN === "true";

export const MANAGER_ENABLED = process.env.REACT_APP_MANAGER_ENABLED === "true";

export const TALKS_URL_STUDIO = process.env.REACT_APP_TALKS_URL_STUDIO || "linkStudio";
export const TALKS_URL_AZIENDA = process.env.REACT_APP_TALKS_URL_AZIENDA || "linkAzienda";

export const NEW_COOKIE_POLICY_URL =
    process.env.REACT_APP_NEW_COOKIE_POLICY_URL || "https://app.teamsystemdigital.com/cookie-policy/index.html";

export const ONE_TRUST_ENABLED = process.env.REACT_APP_ONE_TRUST_ENABLED || "false";

export const TSID_PROFILE_URL =
    process.env.REACT_APP_TSID_PROFILE_URL || "https://identity-stage.teamsystem.com/Profile/Home";

export const NEW_INTERMEDIARY_APP_ID = process.env.REACT_APP_NEW_INTERMEDIARY_APP_ID || "notset";

export const NEW_CUSTOMERMANAGEMENT_APP_ID = process.env.REACT_APP_NEW_CUSTOMERMANAGEMENT_APP_ID || "notset";
export const PORTALE_SERVICE_ID = process.env.REACT_APP_ENV !== "local" ? "PORTALE" : "localPortale";
export const DEADLINE_APP_ID = process.env.REACT_APP_DEADLINE_APP_ID || "notset";
export const CYBER_APP_ID = process.env.REACT_APP_CYBER_APP_ID || "notset";
export const BUSINESS_INTELLIGENCE_APP_ID = process.env.REACT_APP_BUSINESS_INTELLIGENCE_APP_ID || "notset";
export const CYBER_BUY_LINK_API = process.env.REACT_APP_CYBER_BUY_LINK_API || "notset";
